import React, { useState, useEffect, useRef } from "react";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import queryString from 'query-string';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './App.css';

const App = () => {
  const [numPages, setNumPages] = useState(null);
  const params = queryString.parse(window.location.search);
  const [scale, setScale] = useState(1.0);
  const page = params.page && !isNaN(params.page) ? Number(params.page) : 1;
  const urlWithoutQueryString = params.url.split('?')[0];
  const fileExtension = urlWithoutQueryString.split('.').pop().toLowerCase();
  const [pageNumber, setPageNumber] = useState(page);
  const [autoScroll, setAutoScroll] = useState(false);
  const autoScrollRef = useRef(null);
  const containerRef = useRef(null);
  const videoRef = useRef(null);

  const handleZoomIn = () => {
    setScale(scale => scale * 2);
  };

  const handleZoomOut = () => {
    setScale(scale => scale / 2);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', () => {
        videoRef.current.currentTime = pageNumber;
        videoRef.current.play();
      });
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('loadedmetadata', () => {
          videoRef.current.currentTime = pageNumber;
          videoRef.current.play();
        });
      }
    };
  }, [pageNumber]);

  const handleWheel = (event) => {
    const { deltaY } = event;
    if (deltaY < 0) {
      setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1));
    } else if (deltaY > 0) {
      setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages));
    }
  };

  useEffect(() => {
    const scrollInterval = autoScroll ? setInterval(() => {
      setPageNumber(prevPageNumber => {
        const nextPageNumber = prevPageNumber + 1;
        if (nextPageNumber > numPages) {
          clearInterval(autoScrollRef.current);
          return prevPageNumber;
        }
        return nextPageNumber;
      });
    }, 3000) : clearInterval(autoScrollRef.current);

    autoScrollRef.current = scrollInterval;
    return () => clearInterval(scrollInterval);
  }, [autoScroll, numPages]);

  useEffect(() => {
    const node = containerRef.current;
    if (node) {
      node.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (node) {
        node.removeEventListener('wheel', handleWheel);
      }
    };
  }, [numPages]);

  const renderContentBasedOnFileType = () => {
    if (fileExtension === 'pdf') {
      return (
        <>
          <nav>
            <button onClick={() => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1)} className="default">Prev</button>
            <button onClick={() => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1)} className="default">Next</button>
            <button onClick={handleZoomIn} className="default">Zoom In</button>
            <button onClick={handleZoomOut} className="default">Zoom Out</button>
            <p>Page {pageNumber} of {numPages}</p>
          </nav>
          <Document file={{ url: decodeURI(params.url) }} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} scale={scale} />
          </Document>
        </>
      );
    } else if (['mp4', 'webm', 'avi', 'mov', 'quicktime', 'wmv'].includes(fileExtension)) {
      return (
        <video ref={videoRef} controls playsinline width="90%" height="auto">
          <source src={decodeURI(params.url)} type={`video/${fileExtension}`} />
          Your browser does not support the video tag.
        </video>
      );
    } else {
      return (
        <iframe
          width="100%"
          height="100%"
          src={"https://docs.google.com/gview?embedded=true&url=" + encodeURIComponent(params.url)}
          title="Document preview"
        />
      );
    }
  };

  return (
    <div ref={containerRef} className={fileExtension === 'pdf' ? 'page_pdf' : 'page'}>
      {renderContentBasedOnFileType()}
    </div>
  );
};

export default App;
