import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // If you are using react-router

const AdobeViewer = ({ clientId }) => {
  const location = useLocation(); // Hook to get the location object

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const fileUrl = queryParams.get('url');
    const fileName = queryParams.get('fileName');

    const script = document.createElement('script');
    script.src = "https://acrobatservices.adobe.com/view-sdk/viewer.js";
    script.onload = () => {
      document.addEventListener("adobe_dc_view_sdk.ready", function () {
        // eslint-disable-next-line no-undef
        var adobeDCView = new AdobeDC.View({ clientId, divId: "adobe-dc-view" });
        adobeDCView.previewFile({
          content: { location: { url: fileUrl } },
          metaData: { fileName: fileName, hasReadOnlyAccess: true }
        }, {  embedMode: "FULL_WINDOW", defaultViewMode: "FIT_PAGE", showAnnotationTools: true, showDownloadPDF: true, showFullScreen: true });
      });
    };
    document.body.appendChild(script);

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [clientId, location.search]); // Depend on clientId and search string

  return <div id="adobe-dc-view"></div>;
};

export default AdobeViewer;
